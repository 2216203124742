import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Components/Widget";
import BaseGridItem from "../Components/BaseGridItem";
import { getDisplay } from "../Utils/TreeUtil";
import jsonParser from "../Utils/JsonParser";
import labels from "../Domain/CategoryTree";

class ProductGridItem extends Component {
  render() {
    let { css, item, onClick } = this.props;

    return (
      <Wrapper css={css} onClick={onClick}>
        <Title css="margin-bottom: 2px;">{item.name}</Title>
        <Widget.Row css="flex-grow: 1;">
          <Widget.Col align="flex-start" css="flex-grow: 1;margin-right: 5px;">
            {/* <Description css="flex-grow: 1;">
                    {`${
                      item.description.length > 20
                        ? item.description.slice(0, 20) + "..."
                        : item.description
                    }`}
                  </Description> */}
            {jsonParser(item.label, []).map((l, i) => {
              let name = getDisplay(labels[0], l);
              return name ? (
                <Label
                  key={"product-grid-item-label" + i}
                  color="#8B8B8B"
                  css="margin-bottom: 5px;"
                >
                  {name}
                </Label>
              ) : null;
            })}
            <OriginalPrice>$560</OriginalPrice>
            <Price>$499</Price>
          </Widget.Col>
          <ImageContainer>
            {/*<Badge>NEW IN</Badge>*/}
            <Image img={item.image || "/images/sample/product-sample.jpg"} />
          </ImageContainer>
        </Widget.Row>
      </Wrapper>
    );
  }
}

const Wrapper = styled(BaseGridItem)`
  padding: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;

  ${props => props.css};
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #4e4e4e;

  ${props => props.css};
`;

const Description = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  color: #4e4e4e;

  ${props => props.css};
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14.5px;
  padding: 3px 10px;
  background-color: ${props => props.color || "lightgray"};
  font-weight: 200;
  font-size: 14px;
  color: white;

  ${props => props.css};
`;

const OriginalPrice = styled.div`
  text-decoration: line-through;
  font-weight: 400;
  font-size: 14px;
  color: #bfbfbf;
  ${props => props.css};
`;

const Price = styled.div`
  font-size: 18px;
  color: #4e4e4e;
`;

const ImageContainer = styled.div`
  position: relative;
  min-width: 50%;
`;

const Image = styled.div`
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  ${props => props.css}
`;

const Badge = styled.div`
  position: absolute;
  top: -35px;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #e0a597;
  width: 58px;
  height: 58px;
  font-weight: 200;
  font-size: 12px;
  color: white;

  ${props => props.css}
`;

export default ProductGridItem;
